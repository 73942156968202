import React, { useEffect, useRef, useState } from 'react'
import { GoHeart } from "react-icons/go";
import { FaRegCommentDots } from "react-icons/fa";
import { HiOutlineShare } from "react-icons/hi";
import img1 from '../../Assets/mapImg1.png'
import postImg from '../../Assets/postImg.png'
import img2 from '../../Assets/mapimg2.png'
import img3 from '../../Assets/mapImg3.png'
import { addDoc, arrayUnion, collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { shuffle } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { differenceInMinutes, differenceInDays } from 'date-fns';
import Loader from '../../loader';
import { Report } from '@mui/icons-material';
import toast, { Toaster } from 'react-hot-toast';




const HomeForyou = () => {
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [postData, setPostData] = useState(null);
    const [following, setfollowingData] = useState(null);
    const [loading, setLoading] = useState(false); // State to track loading
    const [firstRender, setFirstRender] = useState(true);
    const [initialPostsCount] = useState(5); // Number of posts to load initially
    const [postsPerPage] = useState(5); // Number of posts to load per page
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const bottomOfPageRef = useRef(); // Ref for the bottom of the page
    const [allPostsFetched, setAllPostsFetched] = useState(false);
    const {uid,postId} = useParams();
    const [userData, setUserData] = useState(null);
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const [comments, setComments] = useState([]); // State to store comments
    const [selectedPostId, setSelectedPostId] = useState(null);
    const [savingData, setSavingData] = useState(false);
    const [activeSection, setActiveSection] = useState('forYou');
    const navigate = useNavigate();
    const [modalPost, setModalPost] = useState(null); // State to store post data for modal
    const postIds = useRef(new Set());
    const [unsubscribeFunctions, setUnsubscribeFunctions] = useState([]);   

    const fetchPosts = async (postId) => {
        try {
            console.log('Fetching posts, postId:', postId);
            setLoading(true);

            // Fetch specific post if postId is provided
            if (postId) {
                const postDocRef = doc(firestore, 'posts', postId);
                const postDocSnap = await getDoc(postDocRef);

                if (postDocSnap.exists()) {
                    const post = postDocSnap.data();
                    const userDoc = await getDoc(doc(firestore, 'users', post.uid));
                    const userData = userDoc.data();
                    const specificPost = { id: postDocSnap.id, ...post, userData };

                    // Place the specific post at the top if not already added
                    if (!postIds.current.has(postDocSnap.id)) {
                        setPostData([specificPost]);
                        postIds.current.add(postDocSnap.id);
                    }
                } else {
                    console.error('Post document not found.');
                }
            }

            // Fetch other posts one by one
            const postsCollectionRef = collection(firestore, 'posts');
            const postsQuerySnapshot = await getDocs(query(postsCollectionRef, orderBy('timestamp', 'desc')));

            for (const docSnap of postsQuerySnapshot.docs) {
                if (!postId || docSnap.id !== postId) {
                    const post = docSnap.data();
                    const userDoc = await getDoc(doc(firestore, 'users', post.uid));
                    const userData = userDoc.data();
                    const newPost = { id: docSnap.id, ...post, userData };

                    // Append the new post to the existing post data if not already added
                    if (!postIds.current.has(docSnap.id)) {
                        setPostData(prevData => Array.isArray(prevData) ? [...prevData, newPost] : [newPost]);
                        postIds.current.add(docSnap.id);
                    }
                }
            }

            setLoading(false);
        } catch (error) {
            console.error('Error fetching posts:', error);
            setLoading(false);
        }
    }
    useEffect(() => {
        const fetchPosts = async () => {
            try {
                setLoading(true);
    
                // Fetch user document based on uid from params
                const userDocRef = doc(firestore, 'users', uid);
                const userDocSnap = await getDoc(userDocRef);
    
                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();
                    const followingArray = userData.following;
    
                    // Fetch posts where uid is present in the following array
                    const postsCollectionRef = collection(firestore, 'posts');
                    const querySnapshot = await getDocs(query(postsCollectionRef, where('uid', 'in', followingArray)));
    
                    const fetchedPostData = [];
    
                    for (const docSnap of querySnapshot.docs) {
                        const post = docSnap.data();
                        const userDoc = await getDoc(doc(firestore, 'users', post.uid));
                        const userData = userDoc.data();
                        fetchedPostData.push({ id: docSnap.id, ...post, userData });
                    }
    
                    if (firstRender) {
                        setfollowingData(shuffle(fetchedPostData).slice(0, initialPostsCount));
                        setFirstRender(false);
                    } else {
                        const filteredPosts = fetchedPostData.filter(newPost => !postData.some(existingPost => existingPost.id === newPost.id));
                        setfollowingData(prevData => [...prevData, ...filteredPosts]);
                    }
    
                    if (fetchedPostData.length < postsPerPage) {
                        setAllPostsFetched(true);
                    }
                    setLoading(false);
                } else {
                    console.error('User document not found.');
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching posts:', error);
                setLoading(false);
            }
        };
    
        fetchPosts();
    }, [uid, firstRender]);
    useEffect(() => {
        if (postId) fetchPosts(postId);
    }, [postId]);
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [loading, allPostsFetched, postData]);
    
    const handleScroll = () => {
        if (bottomOfPageRef.current && bottomOfPageRef.current.getBoundingClientRect().bottom <= window.innerHeight) {
            if (!loading && !allPostsFetched) {
                fetchNextPost();
            }
        }
    };
    

    const fetchNextPost = async () => {
        try {
            setLoading(true);
            const postsCollectionRef = collection(firestore, 'posts');
            const postsQuerySnapshot = await getDocs(query(postsCollectionRef, orderBy('timestamp', 'desc')));
    
            let startIdx = postData.length;
    
            if (startIdx < postsQuerySnapshot.docs.length) {
                const docSnap = postsQuerySnapshot.docs[startIdx];
                const post = docSnap.data();
                const userDoc = await getDoc(doc(firestore, 'users', post.uid));
                const userData = userDoc.data();
                const newPost = { id: docSnap.id, ...post, userData };
    
                // Set post data one by one
                setPostData(prevData => Array.isArray(prevData) ? [...prevData, newPost] : [newPost]);
            }
    
            if (startIdx + 1 >= postsQuerySnapshot.docs.length) {
                setAllPostsFetched(true);
            }
    
            setLoading(false);
        } catch (error) {
            console.error('Error fetching next posts:', error);
            setLoading(false);
        }
    };
    
  
    useEffect(() => {
        const fetchInitialPosts = async () => {
            try {
                setLoading(true);

                if (postId) {
                    await fetchPosts(postId);
                } else {
                    // Fetch initial posts normally if no postId is provided
                    await fetchPosts();
                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching initial posts:', error);
                setLoading(false);
            }
        };

        fetchInitialPosts();
    }, [postId]);
    
    const handleSectionChange = (section) => {
        setActiveSection(section);
    };

    
    const handleLike = async (postId, index) => {
        try {
            const updatedPostData = [...postData];
            const currentPost = updatedPostData[index];

            const alreadyLiked = currentPost.likes && currentPost.likes.includes(uid);

            if (alreadyLiked) {
                currentPost.likes = currentPost.likes.filter(id => id !== uid);
            } else {
                currentPost.likes = (currentPost.likes || []).concat(uid);
            }

            currentPost.liked = !alreadyLiked;

            setPostData(updatedPostData);
            const postRef = doc(firestore, 'posts', postId);
            await updateDoc(postRef, {
                likes: currentPost.likes
            });

            // Add notification for like
            await addNotification(uid, currentPost.uid, alreadyLiked ? 'unliked your post' : 'liked your post');

        } catch (error) {
            console.error('Error toggling like:', error);
        }
    };
    const handleReport = async (postId, index) => {
        try {
            const updatedPostData = [...postData];
            const currentPost = updatedPostData[index];
    
            // Ensure currentPost.reports is initialized as an array
            currentPost.reports = currentPost.reports || [];
    
            const alreadyReported = currentPost.reports.includes(uid);
    
            if (!alreadyReported) {
                // If uid is not found in reports, add it
                currentPost.reports.push(uid);
            } else {
                // If uid is found in repo rts, do nothing
              
            }
    
            setPostData(updatedPostData);
            const postRef = doc(firestore, 'posts', postId);
            await updateDoc(postRef, {
                reports: currentPost.reports
            });
            toast.success("Post reported Successfully.");
            //  toast.success('Post reported Successfully')
            // Add notification for reporting if needed
            // await addNotification(uid, currentPost.uid, alreadyReported ? 'removed report from your post' : 'reported your post');
    
        } catch (error) {
            console.error('Error toggling report:', error);
        }
    };
    

    const addComment = async (postId, commentText) => {
        try {
            const postDataItem = postData.find(post => post.id === postId);
            if (!postDataItem) {
                console.error('Post data not found for postId:', postId);
                return;
            }
            const commentData = {
                postId,
                commentDescription: commentText,
                senderUid: uid,
                recieverUid: postDataItem.uid,
                timestamp: new Date(),
                commentImageURL:[],
                likes:[],
                parentId:"",
            };
            const commentRef = await addDoc(collection(firestore, 'comments'), commentData);
            await updateDoc(commentRef, { commentId: commentRef.id });
            const postRef = doc(firestore, 'posts', postId);
            await updateDoc(postRef, {
                comment: arrayUnion(commentRef.id),
            });
            setPostData(prevData => {
                return prevData.map(post => {
                    if (post.id === postId) {
                        return {
                            ...post,
                            comment: [
                                ...(post.comment || []),
                                {
                                    id: commentRef.id,
                                    text: commentText,
                                    recieverUid: postDataItem.uid,
                                },
                            ],
                        };
                    }
                    return post;
                });
            });

            // Add notification for comment
            await addNotification(uid, postDataItem.uid, `Comment on your post: ${commentText}`);
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };
    const handleSendComment = async () => {
        try {
            const commentInput = document.querySelector('.commentInput');
            const commentText = commentInput.value.trim();
    
            commentInput.value = '';
    
            if (commentText) {
                await addComment(selectedPostId, commentText);
            }
        } catch (error) {
            console.error('Error sending comment:', error);
        }
    };
    
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userDocRef = doc(firestore, 'users', uid); // Use UID from route params
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    setUserData(userDocSnap.data());
                } else {
                    console.log('User document does not exist');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
    
        fetchUserData();
    }, [uid]);
    const openModal = async (postId) => {
        try {
            setSavingData(true);
            setShowModal(true);
            setSelectedPostId(postId);
            const postRef = doc(firestore, 'posts', postId);

            // Set up snapshot listener for the post
            const unsubscribePost = onSnapshot(postRef, async (postDoc) => {
                if (postDoc.exists()) {
                    const post = postDoc.data();
                    const userDoc = await getDoc(doc(firestore, 'users', post.uid));
                    const userData = userDoc.data();
                    setModalPost({ id: postId, ...post, userData });
                } else {
                    console.log('Post does not exist');
                }
            });

            // Set up snapshot listener for comments
            const commentsQuery = query(collection(firestore, 'comments'), where('postId', '==', postId));
            const unsubscribeComments = onSnapshot(commentsQuery, async (commentsSnapshot) => {
                const fetchedComments = [];
        
                for (const commentDoc of commentsSnapshot.docs) {
                    const comment = commentDoc.data();
                    const userDoc = await getDoc(doc(firestore, 'users', comment.senderUid));
                    const userData = userDoc.data();
                    fetchedComments.push({ ...comment, userData });
                }
        
                setComments(fetchedComments);
            });

            // Store unsubscribe functions to clean up later if needed
            setUnsubscribeFunctions([unsubscribePost, unsubscribeComments]);

            setSavingData(false); // Move this line after the snapshot setup to ensure loader is shown
        } catch (error) {
            console.error('Error opening modal:', error);
            setSavingData(false); // Ensure loader is hidden in case of error
        }
    };
  
    const getTimeDifference2 = (timestamp) => {
        if (!timestamp || typeof timestamp.toDate !== 'function') {
            return '';
        }
    
        const now = new Date(); // Device's current time
        const commentTime = new Date(timestamp.toDate()); // Convert Firestore timestamp to Date object
        const timeDifference = now.getTime() - commentTime.getTime(); // Difference in milliseconds
        const secondsDifference = Math.floor(timeDifference / 1000);
        const minutesDifference = Math.floor(secondsDifference / 60);
        const hoursDifference = Math.floor(minutesDifference / 60);
        const daysDifference = Math.floor(hoursDifference / 24);
        const monthsDifference = Math.floor(daysDifference / 30); // Approximate months
        
        if (isNaN(commentTime.getTime())) {
            return '';
        } else if (secondsDifference < 60) {
            return `${secondsDifference} second${secondsDifference !== 1 ? 's' : ''} ago`;
        } else if (minutesDifference < 60) {
            return `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''} ago`;
        } else if (hoursDifference < 24) {
            return `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
        } else if (daysDifference < 30) {
            return `${daysDifference} day${daysDifference !== 1 ? 's' : ''} ago`;
        } else {
            return `${monthsDifference} month${monthsDifference !== 1 ? 's' : ''} ago`;
        }
    };
    
    const handleCloseModal = () => {
        unsubscribeFunctions.forEach(unsubscribe => unsubscribe());
        setShowModal(false);
        setModalPost(null);
        setComments([]);
        setModalPost(null); // Clear modal post data
    };
    
    const handleShare = (postId) => {
        const uid = localStorage.getItem('uid'); // Assuming user ID is stored in localStorage
        let shareableLink = `${window.location.origin}/welcome`;
        
        if (postId) {
            shareableLink = `${window.location.origin}/welcome?postId=${postId}`;
        }
    
        if (navigator.share) {
            navigator.share({
                title: 'Check out this post!',
                text: 'Take a look at this amazing post.',
                url: shareableLink,
            }).then(() => {
                console.log('Thanks for sharing!');
            }).catch(console.error);
        } else {
            navigator.clipboard.writeText(shareableLink).then(() => {
                alert('Link copied to clipboard. You can share it manually.');
            }).catch(console.error);
        }
    };
    
    
    const addNotification = async (sender, receiver, text) => {
        try {
            const notificationData = {
                sender,
                receiver,
                text,
                timestamp: new Date(),
            };
            await addDoc(collection(firestore, 'notifications'), notificationData);
        } catch (error) {
            console.error('Error adding notification:', error);
        }
    };
    const userProfile = (uiddd) => () => {
        navigate(`/userprofile/${uid}/${uiddd}`);
    }
    return (
        <>
        <Toaster position='top-center'/>
               {savingData && <Loader/>}

            <div className='flex flex-col px-[10px] sm:px-[20px] pt-[100px] pb-[70px] lg:pb-[20px] py-[30px]'>
                <div className='flex min-w-[460px] w-full items-center justify-between px-[10%]'>
                    <button onClick={() => handleSectionChange('forYou')}>
                    <div className='text-[13px] montserratFamily text-[#000000] leading-[15px]'>For You</div>
                    </button>
                   <button onClick={() => handleSectionChange('following')}>
                   <div className='text-[#B0B0B0] montserratFamily text-[13px] leaidng-[15px] '>Following({userData?.following ? userData?.following.length : 0})</div>
                   </button>
                </div>
                {activeSection === 'forYou' && (
                <div className='flex flex-col'>
                    {postData && postData.map((item, index) => (
                        <div key={index} className='mb-[15px] w-full max-w-[566px] rounded-[12px] bg-[#FFFFFF] p-[20px] flex flex-col '>
                            <div className='flex items-center gap-[10px]'>
                                <img src={item?.userData?.imageURL ? item?.userData?.imageURL : "https://cdn-icons-png.flaticon.com/128/4140/4140077.png"} className='w-[61px] h-[53px] rounded-[50%] object-cover' alt="" />
                                <div className='flex- flex-col'>
                                    <button onClick={userProfile(item?.userData?.uid)}>
                                    <div className='text-[13px] leading-[15px] font-semibold montserratFamily'>{item.userData?.username}</div>
                                    </button>
                                    <div className='text-[10px] leading-[12px] text-[#B0B0B0] font-semibold montserratFamily'>{getTimeDifference2(item?.timestamp)}</div>
                                </div>
                            </div>
                            <div className={`mt-[12px] max-w-[490px] font-medium text-[13px] montserratFamily text-[#000000] leading-[18px] ${item.language === 'Arabic' ? 'text-right' : ''}`}>
                    <span className='christfamily text-[13px] leading-[13px] text-[#3BA7FF] mr-[3px]'>
                        {item.language === 'Arabic' ? '  أتمنى' : 'I wish'}
                    </span>
                    {item.description}
                </div>

                {item.postImageURL && item.postImageURL.length > 0 ? (
            <div className='flex item-center justify-center mt-[10px] gap-[8px] cursor-pointer'  onDoubleClick={() => handleLike(item.id, index)}>
                {/* If only one image, display it alone */}
                {item.postImageURL.length === 1 ? (
                    <img
                        src={item.postImageURL[0]} 
                        className='mt-[12px] w-full h-[180px] min-w-[full] sm:h-[250px] rounded-[10px] cursor-pointer object-contain rounded-[10px]' 
                        alt=""
                    />
                ) : (
                    // If multiple images, display them in a grid layout
                    <div className='flex flex-col gap-[8px]'>
                        {item.postImageURL.map((imageUrl, index) => (
                            <div className='flex item-center justify-center mt-[10px] gap-[8px]' key={index}>
                                {index === 0 && (
                                    <img src={imageUrl} className='w-[200px] md:w-[244px] h-[170px] md:h-[226px] rounded-[10px]' alt="" />
                                )}
                                {index === 0 && (
                                    <div className='flex flex-col gap-[8px]'>
                                        <img src={item.postImageURL[1]} className='md:w-[96px] w-[80px] h-[80px] md:h-[109px] rounded-[10px]' alt="" />
                                        <img src={item.postImageURL[2]} className='md:w-[96px] w-[80px] h-[80px] md:h-[109px] rounded-[10px]' alt="" />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        ) : null /* If postImageURL is not found or empty, don't render anything */}
                            <div className='flex justify-between items-center mt-[15px]'>
                            <div className='flex items-center gap-[5px] cursor-pointer' onClick={() => handleLike(item.id, index)}>
                            <GoHeart className={`${item.likes && item.likes.includes(uid) ? 'text-[#FF002E]' : 'text-[#A2A2A2]'} text-[20px]`} />
                                    <div className='text-[8px] text-[#000000] leading-[10px]'>{item.likes ? item.likes.length : 0}Likes</div>
                                </div>
                                <div className='flex items-center gap-[5px]' >
                                    <FaRegCommentDots className='text-[#A2A2A2] text-[20px] cursor-pointer'  onClick={() => openModal(item.id)}/>
                                    <div className='text-[8px] text-[#A2A2A2] leading-[10px] cursor-pointer' onClick={() => openModal(item.id)}>{item.comment ? item.comment.length : 0}Comments</div>
                                </div>
                                <div className='flex items-center gap-[5px] cursor-pointer' onClick={() => handleShare(item.id)}>
                                    <HiOutlineShare className='text-[#A2A2A2] text-[20px]'  onClick={() => handleShare(item.id)} />
                                    <div className='text-[8px] text-[#A2A2A2] leading-[10px]'>234 Shares</div>
                                </div>
                                <div className='flex items-center gap-[5px] cursor-pointer' o onClick={() => handleReport(item.id, index)}>
                                   
                                    <div className='text-[8px] text-[#A2A2A2] leading-[10px]'>Report</div>
                                </div>
                            </div>
                            {expandedIndex === index && (
    <div className='flex items-center justify-between w-full py-[20px] commentSection'>
        <div className='flex gap-[10px] items-center'>
            <img src={userData?.imageURL} className='w-[43px] h-[43px] rounded-full' alt="" />
            <input type="text" className='commentInput' placeholder='Type here' />
        </div>
        <button className='sendBtn' onClick={() => handleSendComment(item.id)}>Send</button>
    </div>
)}

                        </div>
                    ))}
                   {loading && (
                        Array.from({ length: 5 }, (_, index) => (
                            <div key={index} className='mb-[15px] min-w-[450px] w-full max-w-[566px] rounded-[12px] bg-[#FFFFFF] p-[20px] flex flex-col '>
                                <div className="loader1">
                                    <div className="wrapper">
                                        <div className="circle" />
                                        <div className="line-1" />
                                        <div className="line-2" />
                                        <div className="line-3" />
                                        <div className="line-4" />
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                    {allPostsFetched && (
                        <div className='mb-[15px] w-full max-w-[566px] rounded-[12px] bg-[#FFFFFF] p-[20px] flex flex-col items-center justify-center '>
                            <p>No more Posts 💁🏽</p>
                        </div>
                    )}
                    <div ref={bottomOfPageRef} />
                    
                </div> )}
                {activeSection === 'following' && (
                    <div className='flex flex-col'>
                    {following && following.map((item, index) => (
                        <div key={index} className='mb-[15px] w-full max-w-[566px] rounded-[12px] bg-[#FFFFFF] p-[20px] flex flex-col '>
                            <div className='flex items-center gap-[10px]'>
                                <img src={item?.userData?.imageURL} className='w-[61px] h-[53px] rounded-[50%] object-cover' alt="" />
                                <div className='flex- flex-col'>
                                   <button onClick={userProfile(item?.userData?.uid)}>
                                   <div className='text-[13px] leading-[15px] font-semibold montserratFamily'>{item.userData?.username}</div>
                                   </button>
                                    <div className='text-[10px] leading-[12px] text-[#B0B0B0] font-semibold montserratFamily'>{getTimeDifference2(item?.timestamp)}</div>
                                </div>
                            </div>
                            <div className={`mt-[12px] max-w-[490px] font-medium text-[13px] montserratFamily text-[#000000] leading-[18px] ${item.language === 'Arabic' ? 'text-right' : ''}`}>
                    <span className='christfamily text-[13px] leading-[13px] text-[#3BA7FF] mr-[3px]'>
                        {item.language === 'Arabic' ? '  أتمنى' : 'I wish'}
                    </span>
                    {item.description}
                </div>
                            {item?.postImageURL && item?.postImageURL.length > 0 && (
    <img src={item?.postImageURL[0]} className='mt-[12px] w-full h-[180px] sm:h-[250px] rounded-[10px] object-contain cursor-pointer' alt="" onDoubleClick={() => handleLike(item.id, index)} />
)}
                            <div className='flex justify-between items-center mt-[15px]'>
                            <div className='flex items-center gap-[5px] cursor-pointer' onClick={() => handleLike(item.id, index)}>
                            <GoHeart className={`${item.likes && item.likes.includes(uid) ? 'text-[#FF002E]' : 'text-[#A2A2A2]'} text-[20px]`} />
                                    <div className='text-[8px] text-[#000000] leading-[10px]'>{item.likes ? item.likes.length : 0}Likes</div>
                                </div>
                                <div className='flex items-center gap-[5px]' >
                                    <FaRegCommentDots className='text-[#A2A2A2] text-[20px] cursor-pointer'  onClick={() => openModal(item.id)}/>
                                    <div className='text-[8px] text-[#A2A2A2] leading-[10px] cursor-pointer' onClick={() => openModal(item.id)}>{item.comment ? item.comment.length : 0}Comments</div>
                                </div>
                                <div className='flex items-center gap-[5px] cursor-pointer' nClick={() => handleShare(item.id)}>
                                    <HiOutlineShare className='text-[#A2A2A2] text-[20px]'  onClick={() => handleShare(item.id)} />
                                    <div className='text-[8px] text-[#A2A2A2] leading-[10px]'>234 Shares</div>
                                </div>
                                <div className='flex items-center gap-[5px] cursor-pointer'>
                                    <div className='text-[8px] text-[#A2A2A2] leading-[10px]'>Report</div>
                                </div>
                            </div>
                            {expandedIndex === index && (
    <div className='flex items-center justify-between w-full py-[20px] commentSection'>
        <div className='flex gap-[10px] items-center'>
            <img src={userData?.imageURL} className='w-[43px] h-[43px] rounded-full' alt="" />
            <input type="text" className='commentInput' placeholder='Type here' />
        </div>
        <button className='sendBtn' onClick={() => handleSendComment(item.id)}>Send</button>
    </div>
)}

                        </div>
                    ))}
                   {loading && (
                        Array.from({ length: 5 }, (_, index) => (
                            <div key={index} className='mb-[15px] min-w-[450px] w-full max-w-[566px] rounded-[12px] bg-[#FFFFFF] p-[20px] flex flex-col '>
                                <div className="loader1">
                                    <div className="wrapper">
                                        <div className="circle" />
                                        <div className="line-1" />
                                        <div className="line-2" />
                                        <div className="line-3" />
                                        <div className="line-4" />
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                    {allPostsFetched && (
                        <div className='mb-[15px] w-full max-w-[566px] rounded-[12px] bg-[#FFFFFF] p-[20px] flex flex-col items-center justify-center '>
                            <p>No more Posts 💁🏽</p>
                        </div>
                    )}
                    <div ref={bottomOfPageRef} />
                    
                </div> )}
            </div>
            <Modal show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>Comments</Modal.Title>
            </Modal.Header>
            <div className="modal-body-container">
                <Modal.Body className="modal-body-scroll">
                    {modalPost && (
                        <>
                            <div className='flex items-center gap-[10px] '>
                                <img src={modalPost.userData?.imageURL || "https://cdn-icons-png.flaticon.com/128/4140/4140077.png"} className='w-[61px] h-[53px] rounded-[50%] object-cover' alt="" />
                                <div className='flex flex-col'>
                                    <div className='text-[13px] leading-[15px] font-semibold montserratFamily'>{modalPost.userData?.username}</div>
                                    <div className='text-[10px] leading-[12px] text-[#B0B0B0] font-semibold montserratFamily'>{getTimeDifference2(modalPost.timestamp)}</div>
                                </div>
                            </div>
                            <div className='mt-[12px] max-w-[490px] font-medium text-[13px] montserratFamily text-[#000000] leading-[18px]'>
                                {modalPost.description}
                            </div>
                            {modalPost?.postImageURL && modalPost?.postImageURL.length > 0 && (
                                <img src={modalPost?.postImageURL[0]} className='mt-[12px] w-full h-[180px] sm:h-[250px] rounded-[10px] object-contain cursor-pointer' alt="" />
                            )}
                            <div>
                                {comments.map((comment, index) => (
                                    <div key={index} className='mt-[12px] flex gap-[10px] '>
                                        <img src={comment.userData?.imageURL || "https://cdn-icons-png.flaticon.com/128/4140/4140077.png"} className='w-[43px] h-[43px] rounded-full' alt="" />
                                        <div className='flex flex-col'>
                                            <div className='text-[12px] leading-[14px] font-semibold montserratFamily'>{comment.userData?.username}</div>
                                            <div className='text-[11px] leading-[13px] text-[#000000] montserratFamily'>{comment.commentDescription}</div>
                                            <div className='text-[10px] leading-[12px] text-[#B0B0B0] font-semibold montserratFamily'>{getTimeDifference2(comment.timestamp)}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </Modal.Body>
                <div className='modal-footer'>
                    <div className='flex items-center justify-between w-full py-[20px]'>
                        <div className='flex gap-[10px] items-center'>
                            <img src={userData?.imageURL || "https://cdn-icons-png.flaticon.com/128/4140/4140077.png"} className='w-[43px] h-[43px] rounded-full' alt="" />
                            <input type="text" className='commentInput' placeholder='Type here' />
                        </div>
                        <button className='sendBtn' onClick={handleSendComment}>Send</button>
                    </div>
                </div>
            </div>
            <style jsx>{`
                .modal-body-container {
                    display: flex;
                    flex-direction: column;
                    max-height: 70vh;
                }
                .modal-body-scroll {
                    flex: 1;
                    overflow-y: auto;
                }
                .modal-footer {
                    border-top: 1px solid #ccc;
                    padding: 10px 20px;
                    background-color: white;
                }
            `}</style>
        </Modal>

        </>
    )
}

export default HomeForyou


