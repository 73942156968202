import React, { useEffect, useState } from 'react';
import imggSuggest from '../../Assets/suggested.png';
import imobxImg1 from '../../Assets/inbox1.png';
import imobxImg2 from '../../Assets/inbox2.png';
import imobxImg3 from '../../Assets/inbox3.png';
import imobxImg4 from '../../Assets/inbox4.png';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { RiSearchLine } from "react-icons/ri";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { arrayUnion, collection, doc, getDocs, getFirestore, updateDoc, orderBy, query, arrayRemove, where, getDoc, onSnapshot } from 'firebase/firestore';
import { shuffle } from 'lodash';
import { firestore } from '../../firebase';
import { format } from 'date-fns';



const RightSidebar = () => {
    const [show, setShow] = useState(false);
    const [suggestedUsers, setSuggestedUsers] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { uid } = useParams();
    const [showAllSuggested, setShowAllSuggested] = useState(false); // New state for controlling whether to show all suggested users
    const [filteredInbox, setFilteredInbox] = useState([]);
    const navigate = useNavigate();
    const [language, setLanguage] = useState('English');

    useEffect(() => {
        const userDocRef = doc(firestore, 'users', uid);

        const unsubscribe = onSnapshot(userDocRef, (doc) => {
            if (doc.exists()) {
                const userData = doc.data();
                setLanguage(userData.language || 'English');
            } else {
                console.log('No such document!');
            }
        }, (error) => {
            console.error('Error getting document:', error);
        });

        // Cleanup listener on unmount
        return () => unsubscribe();
    }, [uid]);

    const fetchSuggestedUsers = async () => {
        try {
            const db = getFirestore();
            const usersCollectionRef = collection(db, 'users');
            const querySnapshot = await getDocs(query(usersCollectionRef, orderBy('joiningDate')));
            let users = querySnapshot.docs.map(doc => ({ userId: doc.id, ...doc.data() }));

            // Filter out the current user
            users = users.filter(user => user.userId !== uid);

            const shuffledUsers = shuffle(users);
            const selectedUsers = showAllSuggested ? shuffledUsers : shuffledUsers.slice(0, 3);

            setSuggestedUsers(selectedUsers);
        } catch (error) {
            console.error('Error fetching suggested users:', error);
        }
    };

    useEffect(() => {
        fetchSuggestedUsers();
    }, [showAllSuggested]); // Refetch suggested users when showAllSuggested changes

    const toggleFollow = async (userId, isFollowing) => {
        try {
            if (!userId) {
                console.error('Invalid userId:', userId);
                return;
            }

            const db = getFirestore(); // Get Firestore instance
            const userDocRef = doc(db, 'users', userId); // Reference to selected user's document

            setSuggestedUsers(prevUsers => 
                Array.isArray(prevUsers) 
                    ? prevUsers.map(user =>
                        user.userId === userId
                            ? {
                                ...user,
                                followers: isFollowing
                                    ? user.followers.filter(followerId => followerId !== uid)
                                    : [...(user.followers || []), uid]
                            }
                            : user
                    )
                    : []
            );

            if (isFollowing) {
                await updateDoc(userDocRef, {
                    followers: arrayRemove(uid)
                });
                await updateDoc(doc(db, 'users', uid), {
                    following: arrayRemove(userId) // Remove the followed user's ID from the current user's following array
                });
            } else {
                await updateDoc(userDocRef, {
                    followers: arrayUnion(uid) // Add current user's UID to the followers array
                });
                await updateDoc(doc(db, 'users', uid), {
                    following: arrayUnion(userId) // Add the followed user's ID to the current user's following array
                });
            }
        } catch (error) {
            console.error('Error toggling follow:', error);
            fetchSuggestedUsers();
        }
    };

    useEffect(() => {
        const fetchEmails = async () => {
            try {
                // Query emails where the recieverUID is equal to the given uid
                const recieverQuery = query(collection(firestore, 'emails'), where('recieverUID', '==', uid));
                const senderQuery = query(collection(firestore, 'emails'), where('senderUID', '==', uid));
    
                // Execute both queries
                const [recieverSnapshot, senderSnapshot] = await Promise.all([
                    getDocs(recieverQuery),
                    getDocs(senderQuery)
                ]);
    
                // Combine results from both queries
                const emails = [];
    
                recieverSnapshot.forEach((doc) => {
                    emails.push({ ...doc.data(), id: doc.id });
                });
    
                senderSnapshot.forEach((doc) => {
                    // Avoid duplicate entries by checking if the email is already in the list
                    if (!emails.some(email => email.id === doc.id)) {
                        emails.push({ ...doc.data(), id: doc.id });
                    }
                });
    
                // Fetch sender details for each email
                const updatedEmails = await Promise.all(emails.map(async (email) => {
                    const senderDocRef = doc(firestore, 'users', email.senderUID);
                    const senderDocSnap = await getDoc(senderDocRef);
                    if (senderDocSnap.exists()) {
                        const senderData = senderDocSnap.data();
                        return { ...email, senderData };
                    } else {
                        console.error("Sender document not found:", email.senderUID);
                        return email; // If sender document not found, return original email
                    }
                }));
    
                // Sort emails by timestamp and limit to the latest 3
                const sortedEmails = updatedEmails.sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate()).slice(0, 3);
    
                setFilteredInbox(sortedEmails);
            } catch (error) {
                console.error('Error fetching emails: ', error);
            }
        };
    
        fetchEmails();
    }, [uid]);
    
    const formatTime = (timestamp) => {
        // Check if timestamp is null or undefined
        if (!timestamp) {
            return "";
        }

        // Convert Firestore timestamp to JavaScript Date object
        const date = timestamp.toDate();

        // Format the date
        return format(date, "hh:mm a");
    };

    const emailNavigate = () => {
        navigate(`/emails/${uid}`);
    }
    const userProfile = (uiddd) => () => {
        navigate(`/userprofile/${uid}/${uiddd}`);
    }
    const truncateDescription = (description) => {
        const words = description.split(" ");
        if (words.length > 8) {
            return words.slice(0, 5).join(" ") + "...";
        }
        return description;
    };
    return (
        <>
            <button variant="primary" className="d-lg-none h-fit" onClick={handleShow}>
                <div className='w-[30px] h-[3px] bg-[black] mb-[4px]'></div>
                <div className='w-[30px] h-[3px] bg-[black] mb-[4px]'></div>
                <div className='w-[30px] h-[3px] bg-[black] mb-[4px]'></div>
            </button>
            <Offcanvas show={show} onHide={handleClose} placement='end' responsive="lg" className='w-full sideOff bg-[#F6F6F6] lg:bg-none'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title><img src={require('../../Assets/bluelogo.png')} className='w-[70px] h-[30px]' alt="" /></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='flex flex-col items-center w-full siSection '>
                        <div className='w-[43%]  justify-center rightSearch'>
                            <div className='bg-[#FFFFFF] mb-[10px] lg:bg-[#F6F6F6] rounded-[12px] flex items-center justify-between w-[347px] h-[50px] pl-[10px] pr-[20px]'>
                                <input type="text" className='w-[247px] bgnone border-none pl-[4px] text-[13px] montserratFamily text-[#000000]' placeholder='Search here...' />
                                <RiSearchLine className='text-[18px] text-[#3BA7FF]' />
                            </div>
                        </div>
                        <div className='flex flex-col bg-[#FFFFFF] sectionBorder w-full max-w-[356px] p-[20px] rounded-[20px] boxShadowDivs'>
                            <div className='text-[#212121] text-[16px] font-medium leading-[18px] mb-[10px]'>{language === 'ds' ? 'مقترح' :'Suggested'}:</div>
                            <div className='w-full flex flex-col'>
                                {suggestedUsers.map((user, index) => (
                                    <div key={index} className='w-full flex justify-between items-center'>
                                        <div className='flex items-center gap-[8px] mt-[10px]'>
                                            <img src={user?.imageURL} className='w-[43px] h-[43px] rounded-full' alt="" />
                                            <div className='flex flex-col'>
                                               <button onClick={userProfile(user.uid)}>
                                               <div className='montserratFamily text-[13px] text-[#000000] font-semibold'>{user.username}</div>
                                               </button>
                                                <div className='text-[#808080] text-[10px] leading-[12px] font-medium montserratFamily'> {user.followers ? user.followers.length : 0}  {language === 'ds' ? 'المتابعون' : 'Followers'}</div>
                                            </div>
                                        </div>
                                        <button className='editBtn bg text-[10px] text-[#000000] font-medium montserratFamily w-[58px] h-[26px] rounded-[13px]'
                                            onClick={() => {
                                                toggleFollow(user.userId, user.followers && user.followers.includes(uid));
                                            }}>
                                            {(user.followers && user.followers.includes(uid)) ? 
                                                (language === 'ds' ? 'المتابعين' : 'Following') : 
                                                (language === 'ds' ? 'يتبع' : 'Follow')
                                            }
                                        </button>
                                    </div>
                                ))}
                            </div>
                            <div className='mt-[30px] w-full h-[1px] bg-[#DFDFDF]'></div>
                            <div className='mt-[10px] text-center text-[#00ACFF] text-[12px] font-medium '>
                                <button onClick={() => setShowAllSuggested(!showAllSuggested)}>
                                    {showAllSuggested ? 
                                        (language === 'ds' ? 'أخف كل شيء' : 'Hide All') : 
                                        (language === 'ds' ? 'اظهار الكل' : 'See All')    
                                    }
                                </button>
                            </div>
                        </div>
                       <button onClick={emailNavigate} className='mt-[20px] 2xl:mt-[40px] flex flex-col bg-[#FFFFFF] sectionBorder w-full max-w-[356px] p-[20px] rounded-[20px] boxShadowDivs'>
                        <div className=' 2xl:mt-[40px] flex flex-col bg-[#FFFFFF] sectionBorder w-full max-w-[356px] p-[20px] rounded-[20px] boxShadowDivs'>
                        <Link className='2xl:mt-[40px] flex flex-col bg-[#FFFFFF] sectionBorder w-full max-w-[356px] p-[20px] rounded-[20px] boxShadowDivs'>
    <div className='masterFamily text-[#000000] text-[17px] leading-[18px] mb-[10px]'>
        {language === 'ds' ? 'صندوق الوارد' : 'Inbox'}
    </div>
    <div className='w-full flex flex-col'>
        {filteredInbox.length === 0 ? (
            <div className='text-center text-[#808080] text-[14px] font-medium'>
                {language === 'ds' ? 'صندوق الوارد الخاص بك فارغ' : 'Your inbox is empty'}
            </div>
        ) : (
            filteredInbox.map((item, index) => (
                <div key={index} className='w-full flex justify-between items-center'>
                    <div className='flex items-center gap-[8px] mt-[10px]'>
                        <img src={item.senderData?.imageURL} className='w-[43px] h-[43px] rounded-full' alt="" />
                        <div className='flex flex-col'>
                            <div className='montserratFamily text-[13px] text-[#000000] font-semibold'>
                                {item.senderData.username}
                            </div>
                            <div className='text-[#000000] text-[10px] leading-[12px] font-medium montserratFamily flex justify-start'>
                                {item.subject}
                            </div>
                            <div className='text-[#808080] text-[9px] leading-[12px] font-medium montserratFamily flex justify-start'>
                                {truncateDescription(item.description)}
                            </div>
                        </div>
                    </div>
                    <button className='text-[10px] text-[#A6A6A6] font-medium montserratFamily'>
                        {formatTime(item.timestamp)}
                    </button>
                </div>
            ))
        )}
    </div>
    <div className='mt-[30px] w-full h-[1px] bg-[#DFDFDF]'></div>
    <div className='mt-[10px] text-center text-[#00ACFF] text-[12px] font-medium'>See All</div>
</Link>

                        </div>
                       </button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default RightSidebar;
