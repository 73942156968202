import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { GoogleAuthProvider } from "firebase/auth";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyD5oGok9AVHQlkkIijOxQWmP_5D0c4VjUg",
  authDomain: "wishesapps-44fee.firebaseapp.com",
  projectId: "wishesapps-44fee",
  storageBucket: "wishesapps-44fee.appspot.com",
  messagingSenderId: "929429268447",
  appId: "1:929429268447:web:24ecb413c3003f45ed066a",
  measurementId: "G-VTDDFJ5T6M"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export const googleProvider = new GoogleAuthProvider();
export { app, analytics, firestore,storage,auth };