import React, { useEffect, useState } from 'react';
import { FaChevronLeft } from "react-icons/fa";
import TextField from '@mui/material/TextField';
import { Avatar, Box, Typography, Chip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Link, useLocation, useParams } from 'react-router-dom';
import { firestore } from '../../firebase';
import '../../Styles/Styles.css';
import { addDoc, collection, getDoc, doc, updateDoc } from 'firebase/firestore';
import Loader from '../../loader';
import toast, { Toaster } from 'react-hot-toast';

const ComposeEmail = ({ toggleDisplayDetail, senderUID }) => {
    const [selectedOption, setSelectedOption] = useState([]);
    const [availableOptions, setAvailableOptions] = useState([]);
    const location = useLocation();
    const { uid } = useParams();
    const replyEmail = location?.state?.replyItem;
    const [savingData, setSavingData] = useState(false);

    useEffect(() => {
        const fetchUserOptions = async () => {
            try {
                const docRef = doc(firestore, "users", uid);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const userData = docSnap.data();
                    const userUids = userData.following;

                    const userPromises = userUids.map(async (userId) => {
                        const userDoc = await getDoc(doc(firestore, "users", userId));
                        if (userDoc.exists()) {
                            return { label: userDoc.data().name, image: userDoc.data().imageURL, id: userId };
                        }
                        return null;
                    });

                    const users = await Promise.all(userPromises);
                    setAvailableOptions(users.filter(user => user !== null));
                }
            } catch (error) {
                console.error("Error fetching user options: ", error);
            }
        };

        fetchUserOptions();
    }, [uid]);

    const clearDetailedEmail = () => {
        toggleDisplayDetail();
    };

    const sendEmail = async () => {
        // Get subject and description values
        const subject = document.querySelector('.subjectInput').value.trim();
        const description = document.querySelector('.emailTextArea').value.trim();
        
        // Determine the receiver UID
        const receiverUID = selectedOption.length > 0 ? selectedOption[0].id : (replyEmail ? replyEmail.senderUID : null);
    
        // Validate inputs
        if (!receiverUID) {
            toast.error('Please select a recipient.');
            return;
        }
       
        if (!description) {
            toast.error('Email body cannot be empty.');
            return;
        }
    
        try {
            const emailRef = await addDoc(collection(firestore, "emails"), {
                senderUID: uid,
                timestamp: new Date(),
                recieverUID: receiverUID,
                subject: subject,
                description: description,
            });
    
            // Update the document with its own ID
            await updateDoc(emailRef, {
                id: emailRef.id
            });
    
            console.log("Email sent successfully with ID: ", emailRef.id);
            setSelectedOption([]);
            document.querySelector('.subjectInput').value = '';
            document.querySelector('.emailTextArea').value = '';
    
            toast.success('Mail sent successfully');
        } catch (error) {
            console.error("Error sending email: ", error);
        }
    };
    

    return (
        <>
        <Toaster position='top-center'/>
        {savingData && <Loader/>}

            <div className='flex flex-col'>
                <div className='flex items-center gap-[5px] cursor-pointer' onClick={clearDetailedEmail}>
                    <FaChevronLeft className='text-[15px] text-[#000000]' />
                    <div className='text-[#000000] mx-auto text-[17px] font-bold leading-[20px] montserratFamily'>
                       Compose Mail
                    </div>
                </div>
                {!replyEmail ? (
                    <Autocomplete
                        className='locationSelect'
                        disablePortal
                        multiple
                        id="combo-box-demo"
                        options={availableOptions}
                        getOptionLabel={(option) => option.label}
                        value={selectedOption}
                        onChange={(event, newValue) => {
                            setSelectedOption(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='To'
                                variant="outlined"
                            />
                        )}
                        renderOption={(props, option) => (
                            <Box display="flex" alignItems="center">
                                <Avatar sx={{ mr: 2 }} src={option.image} />
                                <Typography {...props}>{option.label}</Typography>
                            </Box>
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    key={index}
                                    avatar={<Avatar src={option.image} />}
                                    label={option.label}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                    />
                ) : (
                    <div className='flex items-center gap-[8px] mt-[15px]'>
                        <img src={replyEmail?.senderData.imageURL} className='w-[45px] h-[45px] rounded-full' alt="" />
                        <div className='flex flex-col'>
                            <div className='text-[13px] leading-[15px] font-semibold text-[#000000] montserratFamily'>{replyEmail?.usrname}</div>
                        </div>
                    </div>
                )}
                <input type="text" className='subjectInput' placeholder='Type subject here' />
                <textarea className='emailTextArea' placeholder='Start Typing here...'></textarea>
                <button onClick={sendEmail} className='w-full max-w-[300px] h-[48px] text-[15px] mt-[30px] mx-auto flex items-center justify-center font-semibold montserratFamily rounded-[28px] border-none bg-[#3BA7FF] text-white '>Send</button>
            </div>
        </>
    );
};

export default ComposeEmail;
